import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import styled from "styled-components"
import { Heading, Text } from "rebass"
import cx from "classnames"
import { graphql, StaticQuery } from "gatsby"
import Carousel from "react-elastic-carousel"

import styles from "./About.module.css"
import { InnerHtmlComponent } from "../components/InnerHtmlComponent"
import { AboutSlide } from "../components/AboutSlide"
import DeviceContext from "../context/DeviceContext"

import prevArrow from "../images/right_arrow.png"
import nextArrow from "../images/left_arrow.png"

const ParentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  height: 100vh;
  width: 100%;
  background: linear-gradient(10deg, #efefef 72%, #f8f8f8 72%);
  
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileParentContainer = styled.div`
  height: 100%;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`

const TopStoriesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  height: 80%;
  width: 100%;
`
const BottomStoriesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
  height: 25%;
  width: 100%;
`

const BottomStoryItem = styled.div`
  align-self: flex-start;
  width: 20%;
  height: 100%;
  position: relative;
  background-color: ${props => props.activeColor};
  outline: none;
  cursor: ${props => (props.isPointer ? "pointer" : "auto")};
  padding-bottom: 15px;
  
  -webkit-box-shadow: 0px 1px 0 1px #f8f8f8;
  -moz-box-shadow: 0px 1px 0 1px #f8f8f8;
  box-shadow: 0px 1px 0 1px #f8f8f8;
`

const BottomStoryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 85%;
  height: auto;
  margin-left: 10%;
  margin-top: 4%;
`

const StoryMediaContainer = styled.div`
  height: 100%;
  width: 20%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const StyledVideo = styled.video`
    display: block;
    object-fit: cover;
    min-height: 100%;
    height: 400px;
`
const TopStoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  width: 80%;
  padding-left: 20%;
`

const StoryHeader = styled.div`
  width: 80%;
`
const StoryContentContainer = styled.div`
  width: 80%;
  line-height: 1.8;
`
const CarouselArrowWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid red;
`
const CarouselArrow = styled.div`
  z-index: 1000;
  position: absolute;
  bottom: 0;
`


const StoryMediaComponent = ({ storyMediaWebm, storyMediaMp4 }) => {
  if (storyMediaWebm && storyMediaMp4) {
    return (
      <StyledVideo
        key={storyMediaWebm.file.url}
        autoPlay
        muted
        loop
        playsInline
        data-autoplay
      >
        {storyMediaWebm.file && (
          <source src={`https:${storyMediaWebm.file.url}`} type="video/webm"/>
        )}

        {storyMediaMp4.file && (
          <source src={`https:${storyMediaMp4.file.url}`} type="video/mp4"/>
        )}
      </StyledVideo>
    )
  }
  return <div/>
}

StoryMediaComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  storyMediaWebm: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  storyMediaMp4: PropTypes.object,
}

const SwipableStories = ({ items, isMobile}) => {

  const slides = items.map((value, index) => {
    const webmFileUrl = `https:${value.node.storyMediaWebm.file.url}`
    const mp4FileUrl = `https:${value.node.storyMediaMp4.file.url}`
    const title = value.node.titleFull
    const txtContent = value.node.contentFull.contentFull

    return (
      <AboutSlide
        index={index}
        title={title}
        mp4FileUrl={mp4FileUrl}
        txtContent={txtContent}
        webmFileUrl={webmFileUrl}
      />
    )
  })

   if ( !isMobile ) {
     return (
       <Carousel
         showArrows
         itemsToShow={1}
         renderArrow={
           ({type, onClick}) => {

             if (type === "PREV") {
               return <CarouselArrow style={{left: '40%'}} onClick={onClick}><img src={nextArrow} alt="" /></CarouselArrow>
             } else {
               return <CarouselArrow style={{right: '40%'}} onClick={onClick}><img src={prevArrow} alt="" /></CarouselArrow>
             }

           }
         }
       >
         {slides}
       </Carousel>
     )
   }

   return (
     <>{slides}</>
   )
}


const BottomStories = ({ items, selectedStory, changeStoryText }) => {
  const isSelectedStory = node =>
    isEmpty(selectedStory) ? false : selectedStory.id === node.id

  return (
    <BottomStoriesContainer>
      {items.map(({ node }, i) => (
        <BottomStoryItem
          key={i}
          activeColor={isSelectedStory(node) ? "#f8f8f8" : "#fff"}
          isPointer
          onClick={() => changeStoryText(node)}
          role="button"
          tabIndex={i}
        >
          <BottomStoryItemContainer>
            <div
              className={cx({
                [styles.bottomStoryActiveBadge]: isSelectedStory(node),
              })}
            />
            <Text
              fontSize={["1rem", "1rem", "2rem"]}
              fontFamily="IBM Plex Sans"
              color="#f6682f"
              textAlign="left"
              fontWeight="600"
              pb="10px"
            >
              {`0${node.order}`}
            </Text>
            <Text
              fontSize={["0.9rem", "1rem", "1.1rem"]}
              fontWeight="500"
              color="solidDark"
              fontFamily="IBM Plex Sans"
              textAlign="left"
            >
              {node.titlePreview}
            </Text>

            <Text
              fontSize={["0.6rem", "0.7rem", "0.8rem"]}
              fontWeight="300"
              fontFamily="IBM Plex Sans"
              textAlign="left"
            >
              {InnerHtmlComponent(node.contentPreview.contentPreview)}
            </Text>
          </BottomStoryItemContainer>
        </BottomStoryItem>
      ))}
    </BottomStoriesContainer>
  )
}

BottomStories.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  selectedStory: PropTypes.object,
  changeStoryText: PropTypes.func.isRequired,
}

const StoryContent = ({ titleFull, contentFull }) => (
  <TopStoryWrapper>
    <StoryHeader>

      <Heading
        color="solidDark"
        fontSize={["1.4rem", "1.8rem", "2.2rem"]}
        fontWeight="200"
        fontFamily="IBM Plex Sans"
        className="noselect"
      >
        {titleFull}
      </Heading>
    </StoryHeader>

    <StoryContentContainer>
      <Text fontWeight="200" marginTop={"2rem"} fontSize={["0.7rem", "1.1rem", "1.5rem"]} fontFamily="IBM Plex Sans">
        {InnerHtmlComponent(contentFull)}
      </Text>
    </StoryContentContainer>
  </TopStoryWrapper>
)

StoryContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  titleFull: PropTypes.string,
  contentFull: PropTypes.string,
}

const extractFirstStory = mediaStories => {
  const [head] = mediaStories
  return {
    id: head.node.id,
    titleFull: head.node.titleFull,
    contentFull: head.node.contentFull.contentFull,
    storyMediaWebm: head.node.storyMediaWebm,
    storyMediaMp4: head.node.storyMediaMp4,
  }
}

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStory: {},
    }
  }

  handleStoryChange = node => {
    this.setState({
      selectedStory: {
        id: node.id,
        titleFull: node.titleFull,
        contentFull: node.contentFull.contentFull,
        storyMediaWebm: node.storyMediaWebm,
        storyMediaMp4: node.storyMediaMp4,
      },
    })
  }

  render() {
    const { index } = this.props
    const device = this.context

    return (
      <StaticQuery
        query={graphql`
          query StoriesNewQuery {
            allContentfulStory(
              filter: { node_locale: { eq: "en-US" } }
              sort: { fields: [order], order: ASC }
            ) {
              edges {
                node {
                  id
                  titlePreview
                  titleFull
                  contentPreview {
                    contentPreview
                  }
                  contentFull {
                    contentFull
                  }
                  updatedAt
                  node_locale
                  order
                  storyMediaMp4 {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                  storyMediaWebm {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const storyItems = data.allContentfulStory.edges.slice(0, 4)
          const mediaStories = storyItems.filter(
            x => x.node.storyMediaWebm && x.node.storyMediaMp4,
          )
          const { selectedStory } = this.state
          const story = isEmpty(selectedStory)
            ? extractFirstStory(mediaStories)
            : selectedStory
          return (
            <div className="section" style={{ zIndex: index }} id="about-anchor">
              <ParentContainer>
                <TopStoriesContainer>
                  <StoryContent
                    titleFull={story.titleFull}
                    contentFull={story.contentFull}
                  />

                  <StoryMediaContainer>
                    <StoryMediaComponent
                      storyMediaWebm={story.storyMediaWebm}
                      storyMediaMp4={story.storyMediaMp4}
                    />
                  </StoryMediaContainer>
                </TopStoriesContainer>

                <BottomStories
                  items={mediaStories}
                  selectedStory={story}
                  changeStoryText={this.handleStoryChange}
                />
              </ParentContainer>

              <MobileParentContainer>
                <SwipableStories items={mediaStories} isMobile={device.isMobile} />
              </MobileParentContainer>
            </div>
          )
        }}
      />
    )
  }
}
About.contextType = DeviceContext;

export default About
