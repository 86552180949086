import React from "react"
import { Text } from "rebass"
import { InnerHtmlComponent } from "./InnerHtmlComponent"
import styled from "styled-components"

const MobileVideoOverlay = styled.div`
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const TextContainerMobile = styled.div`
  display: flex;
  text-align: left;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-top: 2rem;
`
const Slide = styled.div`
   position: relative;
   height: 100vh;
   overflow: hidden;
   width: 100%;
`

const BackgroundVideo2 = styled.video`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
`

export const AboutSlide = ({index, webmFileUrl, mp4FileUrl, title, txtContent }) => (
  <Slide key={index} className="slide">
    <BackgroundVideo2 playsInline autoPlay muted loop data-keepplaying>
      <source src={webmFileUrl} type="video/webm" />
      <source src={mp4FileUrl} type="video/mp4" />
    </BackgroundVideo2>

    <MobileVideoOverlay className="slide-container">
      <TextContainerMobile>
        <Text
          color="solidDark"
          textAlign="left"
          fontSize={["1.2rem", "1.4rem", "1.6rem"]}
          fontFamily="IBM Plex Sans"
          fontWeight="400"
        >
          {title}
        </Text>
        <Text
          color="solidDark"
          marginTop={["1rem", "2rem"]}
          fontSize={["1rem", "1.2rem", "1.4rem"]}
          fontFamily="IBM Plex Sans"
          fontWeight="300"
        >
          {InnerHtmlComponent(txtContent)}
        </Text>
      </TextContainerMobile>
    </MobileVideoOverlay>
  </Slide>
)