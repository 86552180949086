import React, { useEffect, useState } from "react"
import { deviceDetect } from "react-device-detect"

const defaultState = { isMobile: false }
const DeviceContext = React.createContext(defaultState)

const DeviceProvider = ({ children }) => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    const device = deviceDetect()
    setMobile(!!device.isMobile)
  }, [])

  return (
    <DeviceContext.Provider
      value={{
        isMobile,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export default DeviceContext
export { DeviceProvider }